<script>
  //import { useCookies } from "vue3-cookies";
  import { useStore } from 'vuex'
  import { mapActions} from "vuex";

  import Layout from "../../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  //import Lottie from "@/components/widgets/lottie.vue";
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  import serverConfig from '@/helpers/config';

  export default {
    setup() {
       
        const store = useStore();
        console.log(store)
        let tk = 'Bearer '+localStorage.getItem('tk')
        return { tk};
    },
    page: {
      title: "Clienti",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Clienti",
        dataEdit:{},
        customer:{
          status:'init',
          code:'',
          sigla:'',
          tipoSoggetto:'',
          ragSoc:'aggiungi `Ragione sociale`',
          codiceFiscale:'aggiungi `Codice Fiscale`',
          partitaIva:'aggiungi `Partita Iva`',
          codiceAteco:'aggiungi `Codice Ateco`',
          nRea:'',
          artigiano:'No',
          email:'',
          telefono:'aggiungi `Telefono`',
          indirizzo:'aggiungi `Indirizzo`',
          citta:'aggiungi `Città`',
          regimeFiscale:'ordinario',
          regimeContabile:'ordinario',

        },
        items: [{
            text: "Gestione clienti",
            href: "/",
          },
          {
            text: "Lista clienti",
            active: true,
          },
        ],
        date1: null,
        date: null,
        rangeDateconfig: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
          mode: "range",
        },
        config: {
          enableTime: false,
          dateFormat: "d M, Y",
        },
        searchQuery: null,
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions2: {
          animationData: animationData1
        },
        page: 1,
        perPage: 50,
        sortRes:'asc',
        value: 'All',
        pages: [],
        dbgruppo:'nd',
        responseList: [],
        filterBy:"-",
        filterByValue:"-",
        paginated:{},
        select:'all',
        search:'',
        n_results:0,
        groupField:[
          {value:'CodCliFor', label:'Codice Cliente'},
          {value:'ragSoc', label:'Ragione Sociale'},
          {value:'nome', label:'Nome'},
          {value:'cognome', label:'Cognome'},
          {value:'codiceFiscale', label:'Codice Fiscale'},
          {value:'partitaIva', label:'Partita Iva'},
          {value:'indirizzo', label:'Indirizzo'},
          {value:'citta', label:'Città'},
        ],
      };
    },
    computed: {

      
      displayedPosts() {
        return this.paginate(this.responseList);
      },
      resultQuery() {
        if (this.searchQuery) {
          const search = this.searchQuery.toLowerCase();
          console.log(this.displayedPosts)
          return this.displayedPosts.filter((data) => {
            return (
              data.code.toLowerCase().includes(search) ||
              data.ragSoc.toLowerCase().includes(search) ||
              data.partitaIva.toLowerCase().includes(search) ||
              data.sigla.toLowerCase().includes(search) ||

              data.email.toLowerCase().includes(search)
            );
          });
        } else {
          return this.displayedPosts;
        }
      },
      
    },


    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    
    beforeMount() {
      axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } 
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
      axios.get(`${serverConfig.EP}users/acl`, 
        { 
          headers: { 'authorization':this.tk}
        }
      ).then(response => {
          if (response.data){
            this.changeLayoutType({layoutType:response.data.layoutType})
          }
      });     
      if (this.$store.state.auth.currentUser){
        console.log(this.$store.state.auth.currentUser.email)
      }
      
    },
    mounted(){
      this.groupField.sort(function (a, b) {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
      
    },
    methods: {
      ...mapActions('auth', ['refreshToken','clearSessionStorage']),
      ...mapActions('layout', ['changeLayoutType', ]),
      axiosInterceptor(){
        axios.interceptors.response.use(
              (response) => {
                  
                  return response;
              },
              (error) => {
                  if (error.response && error.response.status === 401) {
                    Swal.fire({
                      title:"Accesso non autorizzato",
                      text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                      icon:"warning",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3500,
                    })
                      this.$router.push('/login')
                      if(localStorage.getItem('tk')){
                        localStorage.removeItem('tk')
                      }
                      return Promise.reject('Unauthorized access');
                  } else if (error.response && error.response.status  === 404) {
                      Swal.fire({
                        title:"Nessun risultato trovato",
                        text:"Mi spiace, nessun risultato trovato",
                        icon:"error",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:2000,
                      })
                      return Promise.reject('The server cannot find the requested resource');
                  }  else if (error.response && error.response.status  === 412) {
                    Swal.fire({
                      title:"Credenziali mancanti",
                      text:"Inserisci delle credenziali valide, per favore.",
                      icon:"warning",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                } else if (error.response && error.response.status  === 423) {
                      Swal.fire({
                        title:"Risorsa non accessibile",
                        text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                        icon:"error",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:2000,
                      })
                      this.$router.push('/login')
                      if(localStorage.getItem('tk')){
                        localStorage.removeItem('tk')
                      }                
                      return Promise.reject('The server cannot find the requested resource');
                  }
                  else if (error.response && error.response.status  === 500) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                      return Promise.reject('Internal Server Error');
                  }
                  else if (error.response && error.response.status  === 502) {
                      Swal.fire({
                        title:"Errore Server",
                        text:"Mi spiace, ma riscontriamo degli errori sul server.",
                        icon:"error",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:2000,
                      })
                      return Promise.reject('Bad Gateway');
                  }
                  else if (error.response && error.response.status  === 503) {
                    Swal.fire({
                      title:"Servizio non disponibile",
                      text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                      return Promise.reject('Service Unavailable');
                  } else {
                    console.log(error.response.status )
                    Swal.fire({
                      title:"Errore imprevisto",
                      text:"Ops! si è verificato un problema...riprova, per favore.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    
                  }

              }
            );
      },
      syncroApp() {
        Swal.fire(
          {
            title: this.$t("t-wa-sync-confirm-setting-title"),
            text: this.$t("t-wa-sync-confirm-setting-text"),
            icon: this.$t("t-wa-sync-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-wa-sync-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-wa-sync-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-wa-sync-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-wa-sync-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
         console.log(result)
          if (result.isConfirmed) {
            alert('Operazione avviata!')
              this.axiosInterceptor()
              axios.get(`${this.UrlServer}customers/import/customersdata/vps/win/esolver`, 
                { 
                  headers: { 'authorization':this.tk},
                  onUploadProgress: (event) =>
                  {
                    console.log('Progress upload...')
                    console.log(event)
                    const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                    let timerInterval;
                    Swal.fire({
                      title: "Caricamento...",
                      html: "Progresso operazione <b></b>.",
                      timer: percentUploaded,
                      timerProgressBar: true,
                      didOpen: () => {
                        Swal.showLoading();
                        const timer = Swal.getPopup().querySelector("b");
                        timerInterval = setInterval(() => {
                          timer.textContent = `${Swal.getTimerLeft()}`;
                        }, 100);
                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      }
                    }).then((result) => {
                      /* Read more about handling dismissals below */
                      if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('Caricamento...')
                      }
                    });

                  },
                onDownloadProgress: (event) =>
                {
                  const percentDownloaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                
                  let timerInterval;
                  Swal.fire({
                    title: "Download data",
                    timer: percentDownloaded,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();
                      /*
                        const timer = Swal.getPopup().querySelector("b");

                        timerInterval = setInterval(() => {
                          timer.textContent = `Operazione conclusa`;
                        }, 100);
                      */
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    }
                  }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                      Swal.fire({
                        title:"Esito operazione",
                        text:"Sincronizzazione avvenuta con successo",
                        icon:"success",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:3000,
                      })
                    }
                  });

                }
                 
                }).then(response=>{
                  console.log(response)
                if (response.data) {
                  Swal.fire(this.$t("t-msg-import") , this.$t("t-msg-content-import"), "success");
                }
              })
          }
        });
      },
      SearchData(){
          if (this.select != ''){
            this.getList()
          }
        },
      resetQuery(){
        this.getSearchList()
      },
      getSearchList(){
        if (this.dbgruppo!='nd'){ 
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}customers/per-page/all`, 
            { 
              params: { 
                perPage: this.perPage,
                page: this.page,
                sortRes: this.sortRes,
                select: this.select,
                search: this.search,
                DBGruppo:this.DBGruppo,
              }, 
              headers: { 'authorization':this.tk}
            }
          ).then(response=>{
            this.responseList = response.data.results.data;
            this.paginated = response.data.results
            this.n_results = response.data.n_results
          })
        } else {
          Swal.fire(this.$t("Riferimento azienda mancante" , "Seleziona l'Azienda di riferimento, per favore", "info"));

        }
      },
      getList(){
        if (this.dbgruppo!='nd'){ 
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}customers/per-page/all`, 
            { 
              params: { 
                perPage: this.perPage,
                page: this.page,
                sortRes: this.sortRes,
                select: this.select,
                search: this.search,
              }, 
              headers: { 'authorization':this.tk}
            }
          ).then(response=>{
            this.responseList = response.data.results.data;
            this.paginated = response.data.results
            this.n_results = response.data.n_results
          })
        } else {
          Swal.fire(this.$t("Riferimento azienda mancante" , "Seleziona l'Azienda di riferimento, per favore", "info"));

        }
      },
      initItem(){
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}customers/register`,this.customer, {headers: { authorization:this.tk}} )
          .then((response)=>{
            this.$router.push(`/customers/detail/${response.data._id}`)
          })
      },
      deletedata(event) {
          Swal.fire(
            {
              title: this.$t("t-delete-confirm-setting-title"),
              text: this.$t("t-delete-confirm-setting-text"),
              icon: this.$t("t-delete-confirm-setting-icon"),
              showCancelButton: true,
              cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
              cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
              confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
              confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
            }
          ).then((result) => {
            if (result.value) {
              this.responseList.splice(this.responseList.indexOf(event), 1);
              try {
                this.axiosInterceptor()
                axios.delete(`${this.UrlServer}customers/${event._id}`)
                  .then(() => {
                    
                    this.getList()
                    Swal.fire(this.$t("t-msg-deleted") , this.$t("t-msg-data-deleted"), "success");
                  })
              }catch(error){
              
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }

            }
          });
      },  
      setPages() {
          let numberOfPages = Math.ceil(this.responseList.length / this.perPage);
          for (let index = 1; index <= numberOfPages; index++) {
            this.pages.push(index);
          }
        },
        paginate(responseList) {
          let page = this.page;
          let perPage = this.perPage;
          let from = page * perPage - perPage;
          let to = page * perPage;
          return responseList.slice(from, to);
        },
        paginateAct(pg){
          this.page = pg
          this.getList()
        },
        getFilter(){
          if (this.search!=''&&this.filterBy!='-'&&this.dbgruppo!='nd'){ 
            this.filterByValue==this.search
            let obj = JSON.stringify({"filterBy":this.filterBy,"filterByValue":this.filterByValue,"search":this.search, "DBGruppo":this.dbgruppo})
            let base64Str  = Buffer.from(obj).toString('base64')
            localStorage.setItem('filterCS',base64Str)
           

            this.axiosInterceptor()
            axios.get(`${this.UrlServer}customers/apply/filter/search/reg`, 
              { 
                params: { 
                  perPage: this.perPage,
                  page: this.page,
                  sortRes: this.sortRes,
                  filterBy: this.filterBy,
                  filterValue: this.filterByValue,
                  search: this.search,
                  dbgruppo: this.dbgruppo,
                }, 
                headers: { 'authorization':this.tk}
              }
            ).then( response => {
              this.responseList = response.data.results.data;
              this.paginated = response.data.results
              this.n_results = response.data.n_results
            })
          } else {
            Swal.fire({
              title:"Termini non presenti",
              text:"Inserisci termine di ricerca ed applica filtro sul campo prescelto.",
              icon:"info",
              showCancelButton:false,
              showConfirmButton:false,
              timer:2500,
            })
          }
    },

    },
    
    components: {
      Layout,
      PageHeader,
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="responseList">
          <div class="card-header border-bottom-dashed">
            <div class="row g-4 align-items-center">
              <div class="col-sm">
                <div>
                
                  <h5 class="card-title mb-0">{{ $t("t-customers-list") }}</h5>
                </div>
              </div>
              <div class="col-sm-auto">
                <div>
                  <button type="button" class="btn btn-info"  @click="syncroApp">
                    <i class="mdi mdi-swap-horizontal align-bottom me-1"></i>
                    Sincronizzazione anagrafiche
                  </button>
                  &nbsp;
                  <button type="button" class="btn btn-primary add-btn me-1" data-bs-toggle="modal" id="create-btn"
                    @click="initItem">
                    <i class="mdi mdi-account-plus-outline align-bottom me-1"></i> Aggiungi cliente
                  </button>

                </div>
              </div>
            </div>
          </div>
          <div class="card-body border-bottom-dashed border-bottom mt-20">
            <form>
              <div class="row rowSearchFormBGce g-4 mb-50">
                <div class="col-lg-6 col-md-12">
                  <div class="">
                    <div class="search-box ms-2">
                      <input type="text" class="form-control" placeholder="Digita termine di ricerca..." v-model="search" @input="resetQuery()" />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-12">
                  <select class="form-select" v-model="dbgruppo">
                    <option value="nd">Seleziona Azienda</option>
                    <option value="SP">Sunservice</option>
                    <option value="ES">S.Energy Solutions</option>
                  </select>
                </div>
                <div class="col-lg-2 col-md-12">
                  <select class="form-select" v-model="filterBy" @change="getGroupField">
                    <option value="-"> Filtra per:</option>
                    <option  v-for="item in groupField" :key="item" :value="item.value">{{item.label}}</option>
                  </select>
                </div>

                <div class="col-lg-2 col-md-12">
                  <button type="button" class="btn btn-primary w-100" @click="getFilter()" >
                    <i class="ri-equalizer-fill me-1 align-bottom"></i>
                      Filtra
                    </button>
                </div>
              </div>
              <!--end row-->
            </form>
          </div>
          <div class="card-header">
              <div class="row align-items-center mb-50">
                <div class="col ">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        Risultati trovati
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">
                    <div class="my-n1 d-flex align-items-center ">
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div class="card-body mt-20">
            <div>
              <div class="table-card mb-1">

                <div class="row thRow  ">

                    <div class="col-lg-1 col-md-12 text-left">
                       Codice
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                      Denominazione
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                      Codice Fiscale
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                      Partita Iva
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                      Indirizzo
                    </div>
                    <div class="col-lg-1 col-md-12 text-left">
                     Città
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                        Azioni
                    </div>
                </div>
                <div class="row list tRow form-check-all d-flex  " v-for=" (item, index) of responseList" :key="index">

                    <div class="col-lg-1 col-md-12 text-left">
                      {{ item.CodCliFor }}
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                      
                      <template v-if="item.TipoSoggetto==3">
                        {{ item.cognome }} {{ item.nome }}
                      </template>
                      <template>
                        {{ item.ragSoc }}
                      </template>
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                      {{ item.codiceFiscale }} 
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                      {{ item.partitaIva }} 
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                      {{ item.indirizzo }} 
                    </div>
                    <div class="col-lg-1 col-md-12 text-left">
                      {{ item.citta }} 
                    </div>
                    <div class="col-lg-2 col-md-12 text-left ">
                        <ul class="list-inline hstack gap-2 mb-0">
                          <template v-if="item.CodCliFor==0">
                            <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                              data-bs-placement="top" title="Rimuovi">
                              <a href="javascript:void(0)"  class="text-danger d-inline-block remove-item-btn" @click="deletedata(item)">
                                <i class="ri-delete-bin-5-fill fs-16"></i>
                              </a>
                            </li>
                          </template>
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Scheda completa">
                            <a href="javascript:void(0)"  @click="$router.push('/customers/detail/'+item._id)">
                              <i class="mdi mdi-badge-account-outline fs-16"></i>
                            </a>
                          </li>
                        </ul>
                    </div>
                </div>
              </div>
              <div class="d-flex tRow justify-content-end mt-3 mb-50" v-if="n_results > perPage">
                    <div class="col-lg-2 col-md-12 text-right">
                        <label class="pt-2">Risultati per pagina:</label>
                    </div>        
                    <div class="col-lg-1 col-md-12 ">
                        <div class="pl-10 pr-20">
                            <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }" v-for="index in paginated.total_pages" :key="index">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                        
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>